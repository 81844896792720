.stimulus-dropdown {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  & p {
    padding: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    &.filter-title {
      color: var(--dark-gray);
      font-size: 0.875rem;
      padding-bottom: 0.25rem;
    }
  }

  & li.divider {
    @apply border-gray-200;
  }

  & li.disabled {
    color: var(--dark-gray);
  }

  & button {
    margin: 0;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    flex: 1;
    padding: 0;
    cursor: pointer;
    color: var(--gray-700);

    &:hover {
      @apply bg-gray-200;
    }
  }

  & button.btn {
    border: 1px solid var(--border-color);
  }

  & > button {
    border-radius: 4px;
    min-height: 2.125rem;
    min-width: 2.125rem;
    justify-content: center;
  }

  & menu.hidden {
    display: none;
  }

  & menu {
    @apply border border-gray-200 shadow;
    overflow: hidden;
    position: absolute;
    background: white;
    border-radius: 0.25rem;
    padding: 0;
    min-width: 12rem;
    z-index: 1000;

    & form.button_to {
      padding: 0;
    }

    & li {
      list-style-type: none;

      & a,
      & button {
        color: var(--gray-700);
        display: flex;
        gap: 0.5rem;
        align-items: center;
        padding: 0.5rem 1rem;
        text-decoration: none;
        width: 100%;

        &:hover {
          @apply bg-gray-100;
        }
      }

      & a[disabled],
      & button[disabled] {
        color: var(--gray-400);
      }
    }
  }
}
