a.link,
button.link {
  @apply text-gray-700 underline;
}

.admin-markdown {
  h1,
  h2,
  h3,
  h4,
  h5 {
    @apply font-bold;
  }
  h1,
  h2 {
    @apply text-lg;
  }
  ul {
    @apply list-disc pl-3 mb-4;
  }
  p {
    @apply mb-4;
    a {
      @apply text-vitoblue-500;
      &:hover {
        @apply underline;
      }
    }
  }
}

a.linkified {
  @apply text-vitoblue-500;
  &:hover {
    @apply text-vitoblue-700;
  }
}

code {
  &.break-anywhere {
    line-break: anywhere;
  }
}
