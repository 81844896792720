.nav-admin {
  a,
  button {
    svg.svg-inline--fa {
      display: none;
      @media only screen and (min-width: 1024px) {
        display: inline-block;
      }
    }
  }
}
