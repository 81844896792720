.new-hub {
  @apply bg-gray-100;
  background-image: url(~new-hub-tr.svg), url(~new-hub-bl.svg),
    url(~new-hub-line-l.svg), url(~new-hub-line-r.svg), url(~new-hub-circle.svg);
  background-position: top right, bottom left, top left, bottom right,
    center right;
  background-repeat: no-repeat;
}

.new-hub .tailwind-form > form {
  --tw-shadow: none;
}
