/*! purgecss start ignore */
table.participation-import {
  th {
    @apply border p-2;
    min-width: 10rem;
    &.column-name {
      @apply bg-gray-100 p-0 text-center text-gray-500;
      vertical-align: top;
      code {
        @apply bg-gray-200 text-gray-500 block p-1 text-sm;
      }
      div {
        @apply py-1 px-3;
      }
    }
    &.index {
      min-width: 5rem;
    }
    &.nothing {
      @apply border-0;
    }
  }
  th {
    @apply text-left font-normal;
  }
  td {
    @apply border p-2;
    &.index {
      @apply w-10 text-right border-0 text-gray-400;
    }
  }
  .good {
    .email,
    .name,
    .salutation {
      @apply bg-green-100;
    }
    .state {
      display: none;
    }
  }
  .state {
    @apply border-0 text-sm;
  }
  .already-invited,
  .already-participating,
  .failed {
    .email,
    .name,
    .salutation {
      @apply bg-red-100;
    }
    .state,
    .message {
      @apply text-red-500;
    }
  }
  .blank-email,
  .duplicate,
  .nothing {
    @apply text-gray-300;
  }
  .invalid-email {
    .email {
      @apply text-red-500;
    }
    .state {
      @apply text-red-500;
    }
  }
}

.preview-email {
  @apply border px-4 my-4 rounded p-5;
  background-color: #f6f6f6;
  text-align: center;
  .preview-email-inside {
    background: white;
    margin: auto;
    max-width: 600px;
    text-align: left;
  }
  p {
    @apply my-4;
  }
  .invite-banner {
    font-size: 24px;
    margin: 2rem auto;
    max-width: 500px;
    width: auto;
    .invite-logo {
      vertical-align: middle;
      width: 40px;
      img {
        height: 40px;
        width: 40px;
      }
    }
    .invite-title {
      h1 {
        text-align: left;
      }
    }
  }
  .body-content {
    padding: 0 1rem;
  }
}

.import-guidance {
  @apply mt-5;
  h2 {
    @apply text-xl;
  }
  h3 {
    @apply text-lg my-2;
  }
  p {
    @apply my-2;
  }
  code {
    @apply bg-gray-200 px-1;
  }
  ul {
    @apply list-disc pl-5;
    li {
      @apply py-1;
    }
  }
  pre {
    @apply bg-gray-100 p-2 text-gray-700;
  }
}
/*! purgecss end ignore */
