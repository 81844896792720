.dropdown-btn {
  @apply inline-flex justify-center w-full px-4 items-center whitespace-nowrap;
}

.dropdown-btn.menu:hover {
  @apply text-white;
}

.dropdown-btn.collection:hover {
  @apply text-gray-100;
}

.dropdown-btn:focus {
  @apply outline-none;
}

/*.dropdown-btn-active {
  @apply border-b-4 border-gray-700;
}*/

.dropdown-btn-rounded {
  @apply rounded border border-gray-300 bg-white leading-5 font-medium text-gray-700;
}

.dropdown-btn-rounded:hover {
  @apply text-gray-500;
}
.dropdown-btn-rounded:focus {
  @apply outline-none border-blue-300 ring-blue-600;
}
.dropdown-btn-rounded:active {
  @apply bg-gray-50 text-gray-800;
}

.dropdown-item {
  @apply flex gap-2 items-center px-4 py-2 text-sm text-gray-700 dark:text-gray-300 w-full text-left cursor-pointer;
}

.dropdown-item:hover {
  @apply bg-gray-100 text-gray-900 dark:bg-gray-700 dark:text-gray-100;
}

.dropdown-item:focus {
  @apply outline-none bg-gray-100 text-gray-900;
}

.dropdown-item-active {
  @apply text-gray-900;
}

.dropdown {
  @apply absolute w-56 rounded shadow-lg mt-2 text-left z-50;
}

.dropdown-right {
  @apply origin-top-right right-0;
}

.dropdown-left {
  @apply origin-top-left left-0;
}
