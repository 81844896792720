.sidebar-footer .trigger {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.v-popover > .trigger {
  width: 100%;
}
.tooltip {
  @apply block;
  z-index: 1000;
  &[aria-hidden="true"] {
    @apply invisible opacity-0;
    transition: opacity 0.15s, visibility 0.15s;
  }
  &[aria-hidden="false"] {
    @apply visible opacity-100;
    transition: opacity 0.15s;
  }
  .tooltip-inner {
    @apply relative bg-white dark:bg-gray-900 dark:border border-gray-200 dark:border-gray-700 text-black dark:text-gray-100 rounded p-4 text-sm leading-normal shadow-xl;
    z-index: 0;
  }
  .tooltip-arrow {
    @apply absolute border-b border-r border-gray-200 dark:border-gray-800 bg-white dark:bg-gray-900;
    width: 11px;
    height: 11px;
    z-index: 1;
  }
  &[x-placement^="top"] {
    .tooltip-inner {
      margin-bottom: 9px;
    }
    .tooltip-arrow {
      bottom: 3px;
      left: calc(50% - 6px);
      transform: scaleX(0.65) rotate(45deg);
    }
  }
  &[x-placement^="bottom"] {
    .tooltip-inner {
      margin-top: 9px;
    }
    .tooltip-arrow {
      top: 3px;
      left: calc(50% - 6px);
      transform: scaleX(0.65) rotate(-135deg);
    }
  }
  &[x-placement^="left"] {
    .tooltip-inner {
      margin-right: 9px;
    }
    .tooltip-arrow {
      right: 3px;
      top: calc(50% - 5px);
      transform: scaleY(0.65) rotate(-45deg);
    }
  }
  &[x-placement^="right"] {
    .tooltip-inner {
      margin-left: 9px;
    }
    .tooltip-arrow {
      left: 3px;
      top: calc(50% - 5px);
      transform: scaleY(0.65) rotate(135deg);
    }
  }
}

.popover-inner {
  padding: 0 !important;
}
.popover {
  &[aria-hidden="true"] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.25s, visibility 0.25s;
  }

  &[aria-hidden="false"] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.05s, visibility 0.05s;
  }
}

.v-tooltip-dropdown .tooltip-arrow {
  display: none;
}

.v-tooltip-dropdown.with-arrow .tooltip-arrow {
  display: block;
  left: -1px;
}

.v-tooltip-dropdown {
  z-index: 1000;
  .popover-inner {
    overflow: hidden;
  }
  &[x-placement^="top"] {
    .tooltip-inner {
      margin-bottom: 4px;
    }
  }
  &[x-placement^="right"] {
    .tooltip-inner {
      margin-left: 4px;
    }
  }
  &[x-placement^="left"] {
    .tooltip-inner {
      margin-right: 4px;
    }
  }
  &[x-placement^="bottom"] {
    .tooltip-inner {
      margin-top: 4px;
    }
  }
}

.vito-dropdown {
  z-index: 99999;
}
