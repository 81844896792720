.markdown {
  h1,
  h2,
  h3,
  h4,
  h5 {
    @apply font-bold;
  }
  h1,
  h2 {
    @apply text-lg;
  }
  * + p,
  * + h1 {
    @apply mt-4 mb-1;
  }
  h1 + p {
    @apply mt-0;
  }
  ul {
    @apply list-disc pl-8 mt-2 mb-4;
    li {
      @apply mb-2;
    }
  }
  a {
    @apply underline;
  }
  code {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    font-size: 0.8rem;
    padding: 0.2rem 0.4rem;
  }
}
