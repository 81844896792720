.tailwind-form {
  @apply flex justify-center flex-col;
  > form {
    @apply bg-white p-4;
  }
  @media (min-width: 640px) {
    > form {
      @apply max-w-screen-sm w-full;
    }
  }
  &.w-100 {
    @media (min-width: 640px) {
      > form {
        width: 100%;
      }
    }
  }
  input.disabled {
    @apply border-transparent;
  }
  legend {
    @apply font-bold text-xl;
  }
  fieldset.group {
    @apply my-10;
    legend {
      @apply border-b border-gray-700 font-semibold pb-2 text-base w-full;
    }
  }

  fieldset {
    &.primary-action {
      @apply mt-5 rounded shadow-sm;
      > {
        @apply flex flex-grow;
      }
    }
    &.secondary-actions {
      @apply mt-5 justify-between;
    }
  }
}

@media (min-width: 640px) {
  .tailwind-form {
    @apply flex-row;
  }
}

.btn-select {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M7 7l3-3 3 3m0 6l-3 3-3-3' stroke='%239fa6b2' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 1.5em 1.5em;
}

.hidden-not-hidden {
  height: 0;
  width: 0;
  label,
  input {
    opacity: 0;
    transform: translate(-9999px, 0px);
  }
}

input[type="color"]::-webkit-color-swatch {
  border: 1px solid #777;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  background: #efefef;
  border-radius: 0.25rem;
  padding: 0.25rem;
}

.stripe-button {
  color:#635BFF;
  height:38px;
  width:180px; 
}

.stripe-button:hover {
  color: #7a73ff;
  cursor: pointer;
}