.section--with-form {
  display: flex;
  align-items: center;
  .col-text {
    div {
      @apply px-4;
    }
    h3 {
      @apply text-lg font-medium leading-6 text-gray-900;
    }
    p {
      @apply mt-1 text-sm leading-5 text-gray-500;
    }
  }
  .col-form {
    @apply mt-5;
  }
  .card {
    @apply shadow;
  }
  .card--header {
    @apply bg-white px-4 py-5 border-b border-gray-200;
    > div {
      @apply -ml-4 -mt-4 flex justify-end items-center flex-wrap;
    }
  }
  .card--header-link {
    @apply ml-4 mt-4 flex-shrink-0 flex;
  }
  .card--body {
    @apply px-4 py-5 bg-white;
    > div {
      @apply grid grid-cols-6 gap-6;
      > div {
        @apply col-span-6;
      }
    }
  }
  .card--footer {
    @apply flex justify-end px-4 py-3 bg-gray-50;
  }
  @media only screen and (min-width: 640px) {
    .col-text {
      div {
        @apply px-0;
      }
    }
    .card {
      @apply rounded overflow-hidden;
    }
    .card--header {
      @apply px-6;
      > div {
        @apply flex-nowrap;
      }
    }
    .card--body {
      @apply p-6;
    }
    .card--footer {
      @apply px-6;
    }
  }
  @media only screen and (min-width: 768px) {
    > div {
      @apply grid grid-cols-3 gap-6;
    }
    .col-text {
      @apply col-span-1;
    }
    .col-form {
      @apply mt-0 col-span-2;
    }
  }
}

.inset {
  margin-top: 4.5rem;
  margin-left: 22rem;
}
