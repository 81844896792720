.btn {
  min-height: 38px;
  @apply border
    border-gray-300
    bg-white
    dark:bg-gray-900
    dark:border-gray-700
    cursor-pointer
    duration-150
    ease-in-out
    font-medium
    items-center
    justify-between
    leading-5
    px-4
    py-2
    relative
    rounded
    text-sm
    text-center
    text-gray-700
    dark:text-gray-200
    transition
    whitespace-nowrap;
  &:hover {
    @apply bg-gray-50 text-gray-500 dark:bg-gray-800 dark:text-white;
  }
  &:focus {
    @apply border-blue-300
      outline-none
      ring-blue-600;
  }
  &:active {
    @apply bg-gray-50
      text-gray-800 dark:bg-gray-900 dark:text-gray-100;
  }
  &.hidden {
    display: none;
  }
  &.btn-mobile-hidden {
    display: none;
  }
  @media (min-width: 640px) {
    &.btn-mobile-hidden {
      display: inline-flex;
    }
  }
}

/*// Button with icon*/

.btn-icon {
  svg {
    @apply align-middle
        -ml-1
        mr-2
        h-5
        w-5;
  }
}

/*// Button sizes*/

.btn-block {
  @apply block
      w-full;
}
.btn-lg {
  @apply items-center px-6 py-3 text-lg font-medium;
}
.btn-sm {
  min-height: 26px;
  @apply leading-4
    px-3
    text-sm;
}
.btn-xs {
  min-height: 26px;
  @apply px-2.5
      py-1
      text-xs;
}

/*// Button styles*/

.btn-primary {
  @apply bg-vitoindigo-500 dark:bg-vitoindigo-500
      border-transparent
      text-white;
  &:hover {
    @apply bg-vitoindigo-900 dark:bg-vitoindigo-900 text-white;
  }
}
.btn-secondary {
  /* Is exactly the same as .btn */
}

.btn-pink {
  @apply bg-vitopink-500
      border-transparent
      text-gray-800;
  &:hover {
    @apply bg-vitopink-900
        text-gray-800;
  }
}
.btn-blue {
  @apply border-blue-600 bg-blue-600 text-white;
  &:hover {
    @apply bg-blue-800 text-white;
  }
  &:focus {
    @apply border-vitoblue-700 ring-blue-600 text-white;
  }
  &:active {
    @apply bg-vitoblue-100 bg-vitoblue-900 text-white;
  }
}
.btn-success {
  @apply bg-green-600 dark:bg-green-600
      border-transparent
      text-white;
  &:hover {
    @apply bg-green-700 dark:bg-green-700
        text-white;
  }
}
.btn-danger {
  @apply bg-red-400 dark:bg-red-600
      border-transparent
      text-white;
  &:hover {
    @apply bg-red-600 dark:bg-red-800
        text-white;
  }
}
.btn-disabled {
  @apply bg-gray-200 border-transparent cursor-default text-gray-400 dark:bg-gray-600 dark:text-gray-300;
  &:hover,
  &:active,
  &:focus {
    @apply text-gray-400 dark:text-gray-100;
  }
}
.btn-link {
  @apply bg-transparent border-transparent shadow-none text-gray-700 dark:text-gray-300;
  &:hover {
    @apply bg-transparent
        text-vitoblue-500;
  }
}

.btn-close {
  @apply py-5;
}

.btn-inline {
  @apply outline-none focus:outline-none;
}

.btn-group {
  @apply flex mt-4 gap-4;
  &--part-of-footer {
    @apply flex flex-col mt-0 px-4 pb-4;
    a.btn,
    button,
    input {
      @apply justify-center mt-4 text-center w-full;
    }
    @media only screen and (min-width: 640px) {
      a.btn,
      button,
      input {
        @apply w-auto;
      }
    }
  }
  a.btn,
  button,
  input {
    @apply justify-center mt-4 text-center w-full;
  }
  @media only screen and (min-width: 640px) {
    a.btn,
    button,
    input {
      @apply w-auto;
    }
  }
  &.card--footer {
    @apply mt-0;
  }
}

@media only screen and (min-width: 640px) {
  .btn-group {
    @apply flex-row justify-end;
    &--part-of-footer {
      @apply flex-row justify-end gap-4;
    }
  }
}

@media only screen and (min-width: 640px) {
  .btn-group--onboarding {
    @apply flex flex-row justify-center;
  }
  .btn-group--center {
    @apply flex flex-row justify-center;
  }
}

.btn-center {
  @apply justify-center;
}

/* prose override */

.prose a.btn.btn-secondary {
  @apply font-medium text-gray-700;
  &:hover {
    @apply bg-gray-50;
  }
}
