.pagy-nav {
  @apply flex justify-end border-t border-gray-100 p-4;
  span {
    a {
      display: inline-block;
      @apply px-2;
    }
    &.active {
      @apply px-2 bg-gray-700 text-vitoprimary-300;
    }
    &.disabled {
      @apply px-2 text-gray-300;
    }
  }
}
