.modal {
  height: 100vh;
  @apply p-4 md:pb-6;
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 999;

  &.window {
    @apply flex-grow;
  }
}

@media (min-width: 640px) {
  .modal {
    @apply inset-0 flex items-center justify-center;
  }
}

.modal--dialog {
  @apply transform transition-all;
  @apply min-h-full;
}

@media (min-width: 640px) {
  .modal--dialog {
    position: relative;
  }
}

@media (min-width: 640px) {
  .modal--dialog {
    min-width: 60ch;
    max-width: 60ch;
  }
}

@media (min-width: 1000px) {
  .modal--dialog--wide {
    min-width: 94ch;
    max-width: 94ch;
  }
}

.modal-window-wrapper {
  @apply flex-col;
}

@media (min-width: 640px) {
  .modal--window {
    @apply mx-16;
  }
}

.modal--window {
  @apply md:rounded transition-all w-full flex flex-col items-center justify-center flex-1;

  margin-top: 1rem;

  .modal--body {
    @apply w-full flex-1 flex flex-col;
  }

  @media (min-width: 640px) {
    .modal--body {
      overflow: hidden;
    }
  }

  @media (min-width: 640px) {
    .modal--window--inner {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
    }
  }

  @media (min-width: 640px) {
    .modal--window--inner {
      .modal--window--title {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        height: 77px;
      }
    }
  }

  @media (min-width: 640px) {
    .modal--window--inner {
      .modal--window--title {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        height: 77px;
      }
    }
  }

  .modal--window--inner {
    .modal--window--title {
      @apply flex items-center border-b p-4;
    }

    @media (min-width: 640px) {
      .modal--window--sidebar {
        position: fixed;
        left: 0;
        top: 77px;
        bottom: 0;
        width: 200px;
        @apply border-r;
        overflow: auto;
        @apply bg-gray-50 py-4 rounded-bl-xl;
      }
    }

    @media (min-width: 640px) {
      .modal--window--main {
        position: fixed;
        left: 200px;
        right: 0px;
        top: 77px;
        bottom: 0;
        overflow: auto;
      }
      .modal--window--main--full {
        position: fixed;
        left: 0px;
        right: 0px;
        top: 77px;
        bottom: 0;
        overflow: auto;
      }
    }
  }
}

.modal--action-button {
  @apply border block rounded-full bg-white dark:bg-gray-900 border-gray-400 dark:border-gray-700 w-4 h-4 p-4 flex items-center justify-center;
  &:hover {
    @apply bg-gray-400 dark:bg-gray-500 text-white dark:text-gray-100;
  }
}

.modal--body {
  @apply rounded bg-white dark:bg-gray-900 dark:text-gray-100 overflow-hidden shadow-xl;
  max-height: 90vh;
}

@media (min-width: 640px) {
  .modal--body {
    @apply mt-0;
  }
}

.modal-icon {
  color: #4f46e5;
}
