.link-primary {
  @apply text-vitoindigo-500 dark:text-vitoindigo-100;
  &:hover {
    @apply text-vitoindigo-900 dark:text-vitoindigo-300;
  }
  &:focus {
    @apply outline-none;
  }
}

.link-primary-active {
  @apply text-vitoindigo-900 dark:text-vitoindigo-300;
}
