.customer-support-border-special {
  border-style: solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(
    to right,
    #ffb795,
    #ffb1b1,
    #b38ecb,
    #7477f1,
    #5473ef,
    #3365ff
  );
  border-bottom-width: 3px;
}
