.img--avatar,
.img--logo {
  border-radius: 50%;
  height: 120px;
  width: 120px;

  &-md {
    height: 100px;
    width: 100px;
  }

  &-sm {
    height: 70px;
    width: 70px;
  }

  &-xs {
    height: 50px;
    width: 50px;
  }

  &-xxs {
    height: 32px;
    width: 32px;
  }

  &-placeholder {
    @apply bg-gray-200 text-black flex items-center justify-center;
    img {
      height: 50%;
      width: 50%;
    }
  },
  &-placeholder-white {
    @apply bg-white text-black flex items-center justify-center;
    img {
      height: 50%;
      width: 50%;
    }
  }
}

.avatar-group {
  .img--avatar,
  .img--logo {
    border: 2px solid white;
  }
}
