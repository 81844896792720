.actions {
  @apply my-5 overflow-hidden border-t border-gray-200;
  > ul {
    @apply bg-white;
    > li {
      @apply p-4 border-b;
      &:last-child {
        border: none;
      }
    }
  }
  nav {
    @apply text-right pt-2;
  }
}
