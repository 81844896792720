.nice-list {
  @apply bg-white overflow-hidden;
  header {
    @apply py-5 border-b border-gray-200;
    h3 {
      @apply text-lg leading-6 font-medium text-gray-900;
    }
    &.nice-list--header {
      @apply flex flex-col;
      .nice-list--title {
        @apply flex-1;
      }
      .nice-list--actions {
        @apply flex flex-col;
        a.btn,
        button {
          @apply justify-center mt-4 w-full;
        }
      }
    }
  }
  nav {
    &.filters {
      @apply px-4 text-sm bg-gray-100 mb-2;
      a {
        @apply inline-block p-2 border-b-2 border-transparent;
        &:hover {
          @apply bg-vitoblue-700 text-white;
        }
        &.active {
          @apply border-black;
        }
        &.disabled {
          @apply opacity-25;
        }
      }
      .disabled {
        @apply inline-block p-2 border-b-2 border-transparent opacity-25;
      }
    }
    &.search {
      @apply bg-gray-700 px-4 pt-2 pb-4 text-sm mb-2;
      label {
        @apply text-sm px-2 py-1 text-gray-100 flex justify-between;
      }
      a {
        @apply border-0 no-underline py-0 px-1 text-gray-100;
        &:hover {
          @apply bg-transparent underline;
        }
      }
      fieldset {
        @apply mx-2 mb-2;
      }
      input {
        @apply py-2 px-4 rounded w-full;
      }
    }
  }
  li {
    @apply border-t border-gray-100;
    &:first-child {
      @apply border-none;
    }
    &.none {
      @apply p-4 italic text-gray-300;
    }
    &.no-link {
      @apply flex px-6 py-4 justify-between;
    }
    > a:not(.grid) {
      @apply block flex px-6 py-4 justify-between;
      &:hover {
        @apply bg-gray-50;
      }
      &:focus {
        @apply bg-gray-50 outline-none;
      }
    }
    > a.grid {
      display: grid;
      @apply px-6 py-4;
      &:hover {
        @apply bg-gray-50;
      }
      &:focus {
        @apply bg-gray-50 outline-none;
      }
      &.participants {
        @apply px-4 py-2;
        grid-template-columns: auto auto;
        .participants--email {
          @apply flex flex-row-reverse flex-wrap gap-2 items-center justify-end mb-1 text-sm;
          grid-row-start: 1;
          grid-row-end: 2;
          grid-column-start: 1;
          grid-column-end: 3;
        }
        .participants--source {
          grid-row-start: 2;
          grid-row-end: 3;
          grid-column-start: 1;
          grid-column-end: 2;
        }
        .participants--status {
          @apply flex items-center justify-end;
          grid-row-start: 2;
          grid-row-end: 3;
          grid-column-start: 2;
          grid-column-end: 3;
        }
        .participants--date {
          @apply flex flex-row gap-2 items-baseline justify-start mt-1;
          grid-row-start: 3;
          grid-row-end: 4;
          grid-column-start: 1;
          grid-column-end: 3;
        }
      }
    }
  }
  .participants--header {
    @apply hidden md:grid;
    grid-template-columns: auto 10rem 10rem 11rem;
  }
  .footnote {
    @apply border-t border-gray-200 px-4 py-2 text-sm;
  }
  footer {
    @apply border-t border-gray-200 bg-gray-100 p-4 flex justify-end;
  }
}

@media only screen and (min-width: 640px) {
  .nice-list {
    header {
      @apply px-6;
      &.nice-list--header {
        @apply flex-row justify-between items-center;
      }
      &.nice-list--header {
        .nice-list--actions {
          @apply flex-row gap-4;

          a.btn,
          button {
            @apply mt-0 w-auto;
          }
        }
      }
    }
    li > a.grid.participants {
      grid-template-columns: auto auto;
      .participants--email {
        @apply flex flex-row-reverse gap-2 items-center justify-end;
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 1;
        grid-column-end: 2;
      }
      .participants--source {
        @apply flex justify-end mb-1;
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 2;
        grid-column-end: 3;
      }
      .participants--status {
        grid-row-start: 2;
        grid-row-end: 3;
        grid-column-start: 2;
        grid-column-end: 3;
      }
      .participants--date {
        @apply mt-0;
        grid-row-start: 2;
        grid-row-end: 3;
        grid-column-start: 1;
        grid-column-end: 2;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .hubs-list .nice-list {
    margin: 0;
  }
  .nice-list li > a.grid.participants {
    @apply py-4;
    grid-template-columns: auto 10rem 10rem 11rem;
    .participants--email {
      @apply block my-auto;
      grid-row-start: 1;
      grid-row-end: 2;
      grid-column-start: 1;
      grid-column-end: 2;
    }
    .participants--source {
      @apply block my-auto;
      grid-row-start: 1;
      grid-row-end: 2;
      grid-column-start: 2;
      grid-column-end: 3;
    }
    .participants--status {
      @apply block my-auto;
      grid-row-start: 1;
      grid-row-end: 2;
      grid-column-start: 3;
      grid-column-end: 4;
    }
    .participants--date {
      @apply block my-auto;
      grid-row-start: 1;
      grid-row-end: 2;
      grid-column-start: 4;
      grid-column-end: 5;
    }
  }
}

.simple-list {
  li {
    @apply border-t border-gray-100 flex p-4 justify-between;
    &:first-child {
      @apply border-none;
    }
  }
}
