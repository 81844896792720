.badge {
  @apply font-medium
    inline-flex
    items-center
    leading-4
    px-2.5
    py-0.5
    rounded-full
    text-xs
    bg-gray-100
    text-gray-800
    dark:bg-gray-800
    dark:text-gray-100;

  &-warning {
    @apply bg-orange-100
      text-orange-800;
  }
  &-danger {
    @apply bg-red-500
      text-white;
  }
  &-green {
    @apply bg-green-100
      text-green-800;
  }
  &-dark {
    @apply bg-gray-700 text-gray-200;
  }
  &-not {
    @apply bg-transparent;
  }
}
